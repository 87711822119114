import { css } from "styled-components"

const theme = {
  black: "black",

  font: {
    // family: '"Open Sans", sans-serif',
    family: '"Helvetica Neue", sans-serif',
    mono: '"Andale Mono Regular", monospace',
    medium: 500,
    bold: 700,
  },
  sizes: {
    break: 768,
    large: 1024,
    content: 800,
  },
  grid: {
    enabled: true,
  },
}

const media = Object.keys(theme.sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${theme.sizes[label]}px) {
      ${css(...args)}
    }
  `
  return acc
}, {})

const grid = Object.keys(theme.grid).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @supports (display: grid) {
      ${css(...args)}
    }
  `
  return theme.grid.enabled ? acc : null
}, {})

export default theme
export { media, grid }

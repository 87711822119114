import React from 'react';
import { Link } from 'gatsby';

const MenuItem = ({ externalUrl, link, label }) => {
  if (link) {
    return (
      <li>
        <PageLink link={link}>{label}</PageLink>
      </li>
    );
  }
  if (externalUrl) {
    return (
      <li>
        <a href={externalUrl} target="_blank" rel="noreferrer noopener">
          {label}
        </a>
      </li>
    );
  }
  return <li>{label}</li>;
};

const PageLink = ({ link, children }) => {
  const getPath = () => {
    switch (link.__typename) {
      case 'SanityCategory':
        return `/category/${link.slug.current}`;
      case 'SanityPainting':
        return `/painting/${link.slug.current}`;
      default:
        return `/${link.slug.current}`;
    }
  };
  return <Link to={getPath()}>{children}</Link>;
};

export default MenuItem;

import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import MenuItem from './MenuItem';
import Social from './Social';

import { useViewport } from './hooks';
import { media } from './theme';
import bg from '../images/Signs.png';

const Nav = () => {
  const viewport = useViewport();
  const {
    sanityMenuOptions: { categoryMenu, categoryMenuLabel, mainMenu },
    sanitySiteSettings: { title },
  } = useStaticQuery(MENUS_QUERY);
  return (
    <StyledNav className="nav" viewport={viewport} bg={bg}>
      {categoryMenuLabel && (
        <span className="menu-label">{categoryMenuLabel}</span>
      )}
      {categoryMenu.length && (
        <ul className="menu category-menu">
          {categoryMenu.map(item => (
            <MenuItem key={item._key} {...item} />
          ))}
        </ul>
      )}
      {mainMenu.length && (
        <ul className="menu page-menu">
          {mainMenu.map(item => (
            <MenuItem key={item._key} {...item} />
          ))}
        </ul>
      )}

      <Social />

      <p className="copyright">
        Copyright William Goodman {new Date().getFullYear()}.
        <br />
        All rights reserved.
      </p>
    </StyledNav>
  );
};

const StyledNav = styled.nav`
  position: absolute;
  z-index: 1;
  background: transparent;
  width: 100%;
  height: ${({ viewport }) => viewport.height}px;
  top: 0;
  left: 0;
  padding: 100px 2.5rem 2rem;
  line-height: 1.5;
  /* background-image: url('${({ bg }) => bg}');
  background-size: cover;
  background-repeat: no-repeat; */

  transform: translateY(-100%);
  transition: 0.25s;
  .open & {
    transform: translateY(0%);
  }

  .copyright {
    opacity: 0.5;
    align-self: end;
    margin-bottom: 0;
    font-family: ${({ theme }) => theme.font.mono};
    font-size: 1.3rem;
    line-height: 1.5;
  }

  @supports (display: grid) {
    display: grid;
    grid-template-rows: auto repeat(2, 1fr) auto 1fr;
    ${media.break`
      grid-template-rows: repeat(4, auto) 1fr;
      /* align-items: start; */
    `}
  }

  ${media.break`
    /* display: block; */
    height: 100%;
    position: static;
    transform: none;
    background: transparent;
    padding: 5rem 2rem;
    line-height: 2;
    /* height: auto; */
    transition: none;
  `}

  .menu-label {
    font-weight: ${({ theme }) => theme.font.bold};
    font-weight: bold;
  }
  .menu {
    padding: 0;
    margin: 0;
    list-style: none;
    font-weight: ${({ theme }) => theme.font.bold};
    margin-bottom: 2rem;
    line-height: 2;
    ${media.break`
      line-height: 1.5;
    `}
    &.category-menu {
      font-size: 2rem;
      font-weight: ${({ theme }) => theme.font.medium};
    }
    &.page-menu {
    }
  }
  a {
    color: inherit;
    text-decoration: none;
  }
`;

const MENUS_QUERY = graphql`
  {
    sanityMenuOptions {
      categoryMenuLabel
      categoryMenu {
        _key
        externalUrl
        label
        link {
          ... on SanityCategory {
            id
            slug {
              current
            }
          }
          ... on SanityPage {
            id
            slug {
              current
            }
          }
          ... on SanityPainting {
            id
            slug {
              current
            }
          }
        }
      }
      mainMenu {
        _key
        externalUrl
        label
        link {
          ... on SanityCategory {
            id
            slug {
              current
            }
          }
          ... on SanityPage {
            id
            slug {
              current
            }
          }
          ... on SanityPainting {
            id
            slug {
              current
            }
          }
        }
      }
    }
    sanitySiteSettings {
      title
    }
  }
`;

export default Nav;

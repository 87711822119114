import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import classNames from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';

import theme, { media } from './theme';
import GlobalStyle from './GlobalStyle';
import Header from './Header';
import Meta from './Meta';

import '../fonts/fonts.css';

import { useViewport } from './hooks';

const Wrapper = ({
  children,
  className,
  seo,
  content,
  title,
  home = false,
}) => {
  const viewport = useViewport();
  const [menuOpen, setMenuOpen] = useState(false);

  const {
    file: {
      childImageSharp: {
        fluid: { src: bg },
      },
    },
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "Signs.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            src
          }
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={theme}>
      <SiteWrapper
        className={classNames('site-wrapper', className)}
        bg={bg}
        viewport={viewport}
      >
        <Meta seo={seo} home={home} content={content} title={title} />
        <Header open={menuOpen} setOpen={setMenuOpen} />
        <PageWrapper
          className="page-wrapper"
          menuOpen={menuOpen}
          viewport={viewport}
        >
          {children}
        </PageWrapper>
        <GlobalStyle />
      </SiteWrapper>
    </ThemeProvider>
  );
};

const PageWrapper = styled.div`
  flex-grow: 1;
  height: calc(100vh - 90px);
  height: ${({ viewport }) => viewport.height - 90}px;
  opacity: ${({ menuOpen }) => (menuOpen ? 0 : 1)};
  transition: 0.2s;
  ${media.break`
    flex: 1 auto; 
    height: 100%;
    height: ${({ viewport }) => viewport.height}px;
    overflow: scroll;
  `}
`;

const SiteWrapper = styled.div`
  background-image: url('${({ bg }) => bg}');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  height: ${({ viewport }) => viewport.height}px;
  display: flex;
  flex-direction: column;

  @supports (display: grid) {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 100%;
    ${media.break`
      display: flex;
    `}
  }

  ${media.break`
    flex-direction: row;
  `}
`;

export default Wrapper;

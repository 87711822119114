import React from 'react';
import styled from 'styled-components';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import classNames from 'classnames';

import MenuToggle from './MenuToggle';
import Nav from './Nav';

import { media } from './theme';

const Header = ({ open, setOpen }) => {
  const logo = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "wg_logo_web.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <StyledHeader className={classNames('header', { open })}>
      <HomeLink to="/" className="header__logo-link">
        <Img
          fluid={logo.file.childImageSharp.fluid}
          alt="William Goodman Art"
          className="header__logo"
        />
      </HomeLink>
      <MenuToggle setOpen={setOpen} open={open} />
      <Nav />
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  padding: 2rem 1.5rem;
  display: flex;
  justify-content: space-between;
  ${media.break`
    flex: 0 0 375px;
    padding: 4rem 3.5rem;
    padding-right: 0;
    display: block;
    
  `}
`;

const HomeLink = styled(Link)`
  display: block;
  flex: 0 1 300px;
  max-width: 300px;
  width: auto;
  margin-right: 1rem;
  position: relative;
  z-index: 2;
  ${media.break`
    flex: 0 0 auto;
  `}
`;

export default Header;

import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from 'react-icons/fa';

import { media } from './theme';

const Social = () => {
  const {
    sanitySiteSettings: {
      social: { facebook, instagram, linkedin, twitter },
    },
  } = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        social {
          facebook
          instagram
          linkedin
          twitter
        }
      }
    }
  `);

  return (
    <SocialList className="social">
      {facebook && (
        <li className="social__item facebook">
          <a
            className="social__link"
            href={facebook}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookF size="25px" />
          </a>
        </li>
      )}
      {instagram && (
        <li className="social__item">
          <a
            className="social__link instagram"
            href={instagram}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram size="25px" />
          </a>
        </li>
      )}
      {twitter && (
        <li className="social__item">
          <a
            className="social__link twitter"
            href={twitter}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter size="25px" />
          </a>
        </li>
      )}
      {linkedin && (
        <li className="social__item">
          <a
            className="social__link linkedin"
            href={linkedin}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedinIn size="25px" />
          </a>
        </li>
      )}
    </SocialList>
  );
};

const SocialList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  /* padding: 2.5rem 2rem; */

  ${media.break`
    padding: 5rem 0;
  `}
  .social {
    &__item {
      margin-right: 1rem;
    }
    &__link {
      color: inherit;
    }
  }
`;

export default Social;

import { createGlobalStyle } from 'styled-components';
// import { media } from './theme';

import normalize from './normalize';

const GlobalStyle = createGlobalStyle`
  
  ${normalize}

  html {
    box-sizing: border-box;
    font-size: 10px;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    scroll-behavior: smooth;

  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    font-family: ${({ theme }) => theme.font.family};
    color: ${({ theme }) => theme.black};
    font-size: 1.6rem;
  }
  h1 {
    margin-top: 0;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .page-title {
    width: ${({ theme }) => theme.sizes.content}px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`;

export default GlobalStyle;

import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const Meta = props => {
  // console.log(props);
  const { home, title, seo } = props;

  const {
    sanitySiteSettings: { customCSS, title: siteTitle },
  } = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        customCSS {
          code
        }
        title
      }
    }
  `);

  const titleTemplate = home
    ? siteTitle
    : `${seo && seo.title ? seo.title : title} | ${siteTitle}`;

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
    >
      <title>{titleTemplate}</title>
      {customCSS && <style type="text/css">{customCSS.code}</style>}
      {seo && seo.metaDescription && (
        <meta name="description" content={seo.metaDescription} />
      )}
      <meta
        name="facebook-domain-verification"
        content="sh79bptzbhgugzytbnuhunuu4qmz6a"
      />
      <meta
        name="google-site-verification"
        content="H-Ui9iSsbXYMLBNlEnGfug0-V7I254xpth0E4QAYS2I"
      />
    </Helmet>
  );
};

export default Meta;

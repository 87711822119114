import React from 'react';
import styled from 'styled-components';

import { media } from './theme';

const MenuToggle = ({ open, setOpen }) => {
  return (
    <Toggle open={open} onClick={() => setOpen(!open)}>
      <span />
      <span />
      <span />
    </Toggle>
  );
};

const Toggle = styled.button`
  background: transparent;
  border: 0;
  display: block;

  width: 50px;
  height: 50px;
  flex: 0 0 50px;
  /* background: blue; */
  position: relative;
  z-index: 2;
  ${media.break`
    display: none;
  `}
  span {
    background: ${({ theme }) => theme.black};
    width: 40px;
    height: 2px;
    left: 50%;
    top: 0;
    position: absolute;
    opacity: 1;
    transition: 0.15s;
    :nth-child(1) {
      transform: translateX(-50%) translateY(${0.32 * 50}px) rotate(0deg);
      .open & {
        transform: translateX(-50%) translateY(${0.5 * 50}px) rotate(45deg);
      }
    }
    :nth-child(2) {
      transform: translateX(-50%) translateY(${0.5 * 50}px) rotate(0deg);
      .open & {
        opacity: 0;
      }
    }
    :nth-child(3) {
      transform: translateX(-50%) translateY(${0.66 * 50}px) rotate(0deg);
      .open & {
        transform: translateX(-50%) translateY(${0.5 * 50}px) rotate(-45deg);
      }
    }
  }
`;

export default MenuToggle;
